import React, { Component } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import AddProvider from "../User/AddProvider";
import API from "../API_Services/ServiceLayer";
import { confirmAlert } from "react-confirm-alert";
import logo from "../../assets/images/logo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
let user = JSON.parse(localStorage.getItem("user"));


const notificationStyles = {
  fontSize: "9px",
  width: "15px",
  height: "15px",
  textAlign: "center",
  lineHeight: "15px",
  color: "#fff",
  fontWeight: "bold",
};
class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      notifications: null,
      passwordIsTypePassword: true,
      cPasswordIsTypePassword: true,
    };
  }
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  componentDidMount() {
    let i = 1;

    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.setState({ username: user["name"] });
      let data = {
        table: "users",
        user_id: user["id"],
        token: localStorage.getItem("token"),
      };

      API.fileupload("api/check-login", data)
        .then((result) => {
          if (result.data.status == "failed") {
            localStorage.clear();
            window.location.reload();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    let time = "";

    this.fetchNotifications();
  }

  notificationOnClick(id) {
    let data = {
      id: id,
    };
    API.postMethod("api/notification_seen_status", "post", data)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          this.componentDidMount();
        }
      });
  }

  fetchNotifications() {
    // get notification(s)
    API.getData("api/issues_notification")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ notifications: data?.data });
      })
      .catch(console.error);
  }

  handleLogout() {
    localStorage.removeItem("token");
    localStorage.clear("");
    window.location.href = "/";
  }
  // handleAutoLogout() {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user");
  //   window.location.href = "/";
  // }

  onCheckPassword(e) {
    let name = e.target.name;

    if (name == "new_password" && e.target.value == "") {
      this.setState({ passErr1: "Enter New Password" });
    }
    if (name == "confirm_password" && e.target.value == "") {
      this.setState({ passErr2: "Enter Confirm Password" });
    }

    if (this.state.password1 !== this.state.password2) {
      this.setState({ matchPssword: "Password Not Matched" });
    } else {
      console.log("password1-error", this.state.password1);
      console.log("password2-error", this.state.password2);
    }
  }

  handleChange(e) {
    let name = e.target.name;

    this.setState({ [name]: e.target.value });
  }

  handleChangePassword() {
    this.setState({ show: true });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    let username = "";
    let role = "";
    let id = "";  
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      let users_role = JSON.parse(localStorage.getItem("role"));
      console.log(users_role,"users_role")
      username = user["name"];
      role = users_role;
      id = user["id"];
    }
    let data = {
      password: this.state.password1,
      user_id: id,
      role_id: role,
    };
    console.log(user, "mydatausers")

    console.log(data,"mydatas");

    API.postMethod("api/password_update","post", data)
    .then((res)=>{ return res.json()})
    .then((res) => {
      console.log(res);
      if (res?.status === 'success') {
        this.setState({ show: false, success: true });
      }
      // Parse the response JSON

    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  successDone = () => {
    localStorage.clear();
    window.location.reload();
  };

  render() {
    let username = "";
    let roles = [];
    let user = {};
    if (localStorage.getItem("user")) {
      user = JSON.parse(localStorage.getItem("user"));
      username = user["name"];
      roles = user["roles"];
    }

    return (
      <div>
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo" to="/">
              <img src={logo} alt="logo" />
            </Link>
            <Link className="navbar-brand brand-logo-mini" to="/">
              <img
                src={require("../../assets/images/logo-mini.svg")}
                alt="logo"
              />
            </Link>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            <button
              className="navbar-toggler navbar-toggler align-self-center"
              type="button"
              onClick={() =>
                document.body.classList.toggle("sidebar-icon-only")
              }
            >
              <span className="mdi mdi-menu"></span>
            </button>
            {/* <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input
                  type="text"
                  className="form-control bg-transparent border-0"
                  placeholder="Search projects"
                />
              </div>
            </form>
          </div> */}
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item nav-profile">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link">
                    <div className="nav-profile-img">
                      <img
                        src={require("../../assets/images/faces-clipart/pic-4.png")}
                        alt="user"
                      />
                      <span className="availability-status online"></span>
                    </div>
                    <div className="nav-profile-text">
                      <p className="mb-1 text-black font-weight-bold text-capitalize">
                        {this.state.username}
                      </p>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="navbar-dropdown">
                    {/* <Dropdown.Item
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <i className="mdi mdi-cached mr-2 text-success"></i>
                    Activity Log
                  </Dropdown.Item> */}
                    <div className="userIdentity text-primary">
                      <i className="mdi mdi-account-circle zoomIcon mr-2"></i>
                      {user?.roles?.[0]?.role_name}
                    </div>
                    <Dropdown.Item
                      href="#"
                      onClick={(event) => this.handleChangePassword()}
                    >
                      <i className="mdi mdi-key-variant mr-2 text-primary"></i>
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={(event) => this.handleLogout()}
                    >
                      <i className="mdi mdi-logout mr-2 text-primary"></i>
                      Signout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              {/* <li className="nav-item">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link count-indicator">
                    <i className="mdi mdi-email-outline"></i>
                    <span className="count-symbol bg-warning"></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="preview-list navbar-dropdown">
                    <h6 className="p-3 mb-0">
                      Messages
                    </h6>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <div className="preview-thumbnail">
                        <img
                          src={require("../../assets/images/faces/face4.jpg")}
                          alt="user"
                          className="profile-pic"
                        />
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                          Mark send you a message
                        </h6>
                        <p className="text-gray mb-0">
                          1 Minutes ago
                        </p>
                      </div>
                    </Dropdown.Item>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <div className="preview-thumbnail">
                        <img
                          src={require("../../assets/images/faces/face2.jpg")}
                          alt="user"
                          className="profile-pic"
                        />
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                          Cregh send you a message
                        </h6>
                        <p className="text-gray mb-0">
                          15 Minutes ago
                        </p>
                      </div>
                    </Dropdown.Item>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <div className="preview-thumbnail">
                        <img
                          src={require("../../assets/images/faces/face3.jpg")}
                          alt="user"
                          className="profile-pic"
                        />
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                          Profile picture updated
                        </h6>
                        <p className="text-gray mb-0">
                          18 Minutes ago
                        </p>
                      </div>
                    </Dropdown.Item>
                    <div className="dropdown-divider"></div>
                    <h6 className="p-3 mb-0 text-center cursor-pointer">
                      4 new messages
                    </h6>
                  </Dropdown.Menu>
                </Dropdown>
              </li> */}
              <li className="nav-item">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link count-indicator">
                    <i className="mdi mdi-bell-outline"></i>
                    {this.state.notifications?.length > 0 && (
                      <span
                        className="count-symbol bg-danger"
                        style={notificationStyles}
                      >
                        {this.state.notifications?.length}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu navbar-dropdown preview-list"
                    style={{ height: "200px", overflowY: "scroll" }}
                  >
                    {(!this.state.notifications ||
                      this.state.notifications?.length === 0) && (
                      <div className="px-2">No notifications</div>
                    )}
                    {
                      // slice(0, 5)
                      this.state.notifications?.map((item) => {
                        return (
                          <div key={item.id}>
                            <div className="dropdown-divider"></div>
                            <Dropdown.Item className="dropdown-item preview-item">
                              <div className="preview-thumbnail">
                                <div className="preview-icon bg-success">
                                  <i className="mdi mdi-calendar"></i>
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  this.notificationOnClick(item.id)
                                }
                                className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                              >
                                <h6 className="preview-subject font-weight-normal mb-1">
                                  {item?.title ?? ""}
                                </h6>
                                <p className="text-gray ellipsis mb-0">
                                  {item?.description ?? ""}
                                </p>
                              </div>
                            </Dropdown.Item>
                          </div>
                        );
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="nav-item nav-logout d-none d-lg-block">
                <a
                  className="nav-link"
                  href="#"
                  onClick={(event) => this.handleLogout()}
                >
                  <i className="mdi mdi-power"></i>
                </a>
              </li>
              {/* <li className="nav-item nav-settings d-none d-lg-block">
              <button
                type="button"
                className="nav-link border-0"
                onClick={this.toggleRightSidebar}
              >
                <i className="mdi mdi-format-line-spacing"></i>
              </button>
            </li> */}
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              onClick={this.toggleOffcanvas}
            >
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
        </nav>
        <Modal
          backdrop="static"
          show={this.state.show}
          onHide={() =>
            this.setState({
              show: false,
              passErr1: "",
              passErr2: "",
              password1: "",

              password2: "",
              matchPssword: "",
            })
          }
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Change Password
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              <Form.Group>
                <label>
                  New Password
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  // type="password"
                  type={
                    this.state.passwordIsTypePassword === true
                      ? "password"
                      : "text"
                  }
                  minLength={6}
                  maxLength={55}
                  name="new_password"
                  // onBlur={(e) => this.onCheckPassword(e)}
                  onFocus={() =>
                    this.setState({ passErr1: "", matchPssword: "" })
                  }
                  value={this.state.new_password}
                  onChange={(e) => this.setState({ password1: e.target.value })}
                  placeholder="Enter New Password"
                  required
                  size="sm"
                  style={{ position: "relative" }}

                />
                  {this.state.passwordIsTypePassword === true ? (
                            <>
                              <button
                                type="button"
                                style={{
                                  position: "absolute",
                                  top: "45px",
                                  right: "20px",
                                  border: "none",
                                  background: "none",
                                  borderLeft: "1px solid #ccc",
                                }}
                                onClick={() =>
                                  this.setState({
                                    passwordIsTypePassword: false,
                                  })
                                }
                              >
                                <AiOutlineEyeInvisible
                                  size="1.5em"
                                  color="#111"
                                />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                style={{
                                  position: "absolute",
                                  top: "45px",
                                  right: "20px",
                                  border: "none",
                                  background: "none",
                                  borderLeft: "1px solid #ccc",
                                }}
                                onClick={() =>
                                  this.setState({
                                    passwordIsTypePassword: true,
                                  })
                                }
                              >
                                <AiOutlineEye size="1.5em" color="#111" />
                              </button>
                            </>
                          )}
                {this.state.passErr1 ? (
                  <span className="text-danger text-small">
                    {this.state.passErr1}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group>
                <label>
                  Confirm New Password
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  // type="password"
                  type={
                    this.state.cPasswordIsTypePassword === true
                      ? "password"
                      : "text"
                  }
                  minLength={6}
                  maxLength={55}
                  onFocus={() =>
                    this.setState({ passErr2: "", matchPssword: "" })
                  }
                  onBlur={(e) => this.onCheckPassword(e)}
                  value={this.state.confirm_password}
                  name="confirm_password"
                  onChange={(e) => this.setState({ password2: e.target.value })}
                  placeholder="Confirm Password"
                  required
                  size="sm"
                  style={{ position: "relative" }}

                />
                   {this.state.cPasswordIsTypePassword === true ? (
                            <>
                              <button
                                type="button"
                                style={{
                                  position: "absolute",
                                  top: "132px",
                                  right: "20px",
                                  border: "none",
                                  background: "none",
                                  borderLeft: "1px solid #ccc",
                                }}
                                onClick={() =>
                                  this.setState({
                                    cPasswordIsTypePassword: false,
                                  })
                                }
                              >
                                <AiOutlineEyeInvisible
                                  size="1.5em"
                                  color="#111"
                                />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                style={{
                                  position: "absolute",
                                  top: "132px",
                                  right: "20px",
                                  border: "none",
                                  background: "none",
                                  borderLeft: "1px solid #ccc",
                                }}
                                onClick={() =>
                                  this.setState({
                                    cPasswordIsTypePassword: true,
                                  })
                                }
                              >
                                <AiOutlineEye size="1.5em" color="#111" />
                              </button>
                            </>
                          )}
                {this.state.passErr2 ? (
                  <span className="text-danger text-small">
                    {this.state.passErr2}
                  </span>
                ) : this.state.matchPssword ? (
                  <span className="text-danger text-small">
                    {this.state.matchPssword}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>

              <div className="d-flex flex-wrap justify-content-end">
                <button
                  type="reset"
                  onClick={() =>
                    this.setState({
                      show: false,
                    })
                  }
                  className="btn btn-light rounded-0"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary mr-2 rounded-0"
                >
                  Update
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          backdrop="static"
          show={this.state.success}
          onHide={() => this.successDone()}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
              <h4 className="text-center">
                Your password updated successfully!
              </h4>
              <button
                type="button"
                className="btn btn-sm btn-primary rounded-0 mt-3 w-50"
                onClick={() => this.successDone()}
              >
                Close
              </button>
            </Modal.Body>
          </Modal.Header>
        </Modal>
      </div>
    );
  }
}

export default Navbar;
