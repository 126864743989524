import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import API from "../API_Services/ServiceLayer";
import PersonIcon from "@mui/icons-material/Person";
import { addMenu } from "../../redux/InitialMenuSlice";
import { connect } from "react-redux";

const adminAccess = localStorage.getItem('adminAccess');

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
   }

 
  
  componentDidMount() {
    this.onRouteChanged();

   

    const userData = JSON.parse(localStorage.getItem("user"));
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

   

    API.getData(`user-management/api/v1/module`)
      .then((res) => res.json())
      .then((result) => {
        if (result.status) {
          const moduleInfo = result.data.filter(
            (i) => i.module_name === "admin"
          );
          this.setState({ moduleMenuList: moduleInfo[0].menu });
        }
      });
    API.getData(`user-management/api/v1/access`)
      .then((res) => res.json())
      .then((result) => {
        this.setState({ loading: true });
        if (result.status) {
          const orgDepId =
            userData?.roles[0]?.role_name === "Super Admin"
              ? 24
              : userData?.user_org_depts[0]?.org_dept_id;
          const orgDepRoleId =
            userData?.roles[0]?.role_name === "Super Admin"
              ? 34
              : userData?.user_org_depts[0]?.org_dept_role_id;
          const accessList = result.data.filter(
            (i) =>
              i.organization_department_id === orgDepId &&
              i.organization_department_role_id === orgDepRoleId
          );
          this.setState({ accessRights: accessList });
          localStorage.setItem("accessList", JSON.stringify(accessList));
          this.setState({ loading: false });
        }
      });
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState((prev) => ({ ...prev, [menuState]: false }));
    } else if (Object.keys(this.state).length === 0) {
      this.setState((prev) => ({ ...prev, [menuState]: true }));
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState((prev) => ({ ...prev, [i]: false }));
      });
      this.setState((prev) => ({ ...prev, [menuState]: true }));
    }
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();

    }
  }

  onRouteChanged() {
    
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/property", state: "addPropertyOpen" },
      { path: "/locations", state: "addLocationOpen" },
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },

      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/property", state: "addPropertyOpen" },
      { path: "/user-management", state: "UserOpen" },
      { path: "/locations", state: "addLocationOpen" },
      { path: "/locations", state: "addLocationOpen" },
      { path: "/roles", state: "RoleOpen" },
      { path: "/access", state: "AccessOpen" },
      { path: "/master-management", state: "MasterOpen" },
      { path: "/booking-management", state: "BookingOpen" },
      { path: "/ruleengine", state: "RuleEngine" },
      { path: "/help-desk", state: "Helpdesk" },
      { path: "/finance-management/member-invoices", state: "InvoiceOpen" },
      { path: "/finance-management/discount-coupons", state: "InvoiceOpen" },
      { path: "/visitor-management", state: "GuestListOpen" },
      { path: "/visitor-management/guest", state: "GuestListOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  getMenuIcon(menuName) {
    switch (menuName) {
      case "User Management":
        return "mdi-contacts";
      case "Property settings":
        return "mdi-hospital-building";
      case "Finance Managerment":
        return "mdi-currency-inr";
      case "Visitor Managerment":
        return "mdi-human-male";
      case "Master Managerment":
        return "mdi-settings";
      case "Help Desk":
        return "mdi-help-box";
      case "Dashboard":
        return "mdi-home";
      case "Booking Management":
        return "mdi-content-save";
      default:
        return ""; // Icon name for unknown menu names
    }
  }
  getOpenMenu(menuName) {
    switch (menuName) {
      case "User Management":
        return "UserOpen";
      case "Property settings":
        return "addPropertyOpen";
      case "Finance Managerment":
        return "InvoiceOpen";
      case "Visitor Managerment":
        return "GuestListOpen";
      case "Master Managerment":
        return "MasterOpen";
      case "Help Desk":
        return "Helpdesk";

      case "Booking Management":
        return "BookingOpen";
      default:
        return ""; // Icon name for unknown menu names
    }
  }


  


  

  render() {

    
    const menuListFromDB = JSON.parse(localStorage.getItem("user"));
    const accessRights = JSON.parse(localStorage.getItem("accessList"));

    const menuList = menuListFromDB?.menuList?.map((i) => {
      const stateAccess = this.getOpenMenu(i.menu_name);
      // this.setState({Reloadingsidebar:""},()=>{
      //   window.location.reload();
      // })
      const { menu_name, menu_url, sub_menu } = i;


      return {
        ...i,

        name: menu_name,
        path: menu_url?.includes("admin")
          ? menu_url.replace("admin", "")
          : `/${i.menu_url}`,
        icon: this.getMenuIcon(i.menu_name),
        expandIcon: sub_menu?.length > 0 ? "menu-arrow" : "",
        open: stateAccess,
        state: this.state[stateAccess],
        submenu:
          sub_menu?.length > 0
            ? sub_menu
                .filter((j) => j.submenu_name !== "MG Employee")
                .map((j) => {
                  const { submenu_name, submenu_url } = j;
                  console.log(submenu_url, "url");
                  return {
                    ...j,
                    id: j.id,
                    name: j.submenu_name,
                    path:
                      submenu_url === "help-desk"
                        ? `/${submenu_url}`
                        : menu_url?.includes("admin")
                        ? `${menu_url.replace("admin", "")}/${submenu_url}`
                        : `/${menu_url}/${submenu_url}`,
                  };
                  
                })
            : [],
            
      };

    });

    
  const filteredMenuArray = menuList
  ?.map((menu) => {
    // Check if adminAccess is 1
    if (adminAccess === '001') {
      return menu;
    } else {
      // Check if any submenu has view_access
      const hasSubmenuWithViewAccess = menu?.submenu?.filter((submenu) => {
        const access = accessRights.find(
          (access) =>
            access?.menu_id === menu?.id &&
            access?.submenu_id === submenu?.id &&
            access.view_access === 1 &&
            access.functionality_id === null &&
            !(submenu.id === 9 && access.add_access !== 1) &&
            !(
              submenu.id === 9 &&
              access.submenu_id !== 10 &&
              access.add_access !== 1
            )
        );
        return access !== undefined;
      });

      // Check if view_access for menu is 1 if it doesn't have any submenus
      const hasMenuWithViewAccess = accessRights?.some(
        (access) =>
          access?.menu_id === menu.id &&
          access?.submenu_id === null &&
          access?.view_access === 1
      );

      // Include menu if it has at least one submenu with view_access or if view_access for menu is 1 and it doesn't have any submenus
      if (
        hasSubmenuWithViewAccess?.length > 0 ||
        (menu.submenu?.length === 0 && hasMenuWithViewAccess)
      ) {
        return { ...menu, submenu: hasSubmenuWithViewAccess };
      }
    }
    return null; // Exclude menu otherwise
  })
  .filter((menu) => menu !== null);


// Move the Dashboard object to the beginning if found
let dashboardIndex = filteredMenuArray?.findIndex((item) => item.name === 'Dashboard');

if (dashboardIndex !== -1) {
  // window.location.reload();
  let dashboardItem = filteredMenuArray?.splice(dashboardIndex, 1)[0]; // Remove the Dashboard object
  filteredMenuArray && filteredMenuArray.unshift(dashboardItem); // Add it to the beginning
}

   

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav mt-2">
          {menuList && menuList?.map((i) => {
            return i.submenu?.length <= 0 ? (
              <li
                className={
                  this.isPathActive(i.path) ? "nav-item active" : "nav-item"
                }
                key={i.id}
              >
                <Link
                  className="nav-link"
                  // to={i.path}
                  to={{ pathname: i.path, state: i }}
                >
                  <span className="menu-title">{i.name}</span>
                  <i className={`mdi ${i.icon} menu-icon`}></i>
                </Link>
              </li>
            ) : (
              <li
                className={
                  this.isPathActive(i.path) ? "nav-item active" : "nav-item"
                }
                key={i.id}
              >
                <div
                  className={i.state ? "nav-link menu-expanded" : "nav-link"}
                  onClick={() => this.toggleMenuState(i.open)}
                  data-toggle="collapse"
                >
                  <span className="menu-title">{i.name}</span>
                  <i className="menu-arrow"></i>
                  <i className={`mdi ${i.icon} menu-icon`}></i>
                </div>
                <ul className="nav flex-column sub-menu">
                  {i.submenu?.map((j) => {
                    return (
                      <Collapse in={i.state}>
                        <li className="nav-item">
                          <Link
                            className={
                              this.isPathActive(j.path)
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={{ pathname: j.path, state: j }}
                          >
                            {j.name}
                          </Link>
                        </li>
                      </Collapse>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    AddMenu: (filteredArr) => dispatch(addMenu(filteredArr)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Sidebar));
