import { createSlice } from "@reduxjs/toolkit";
import { RADIO_MODULE } from "./helper";

const initialState = {
  moduleList: [],
  moduleData: {
    id: null,
    module_name: "",
    description: "",
    module_url: "",
    status: null,
    menu: [],
    functionality: [],
    // MENU
    menu_id: null,
    menu_name: "",
    menu_description: "",
    menu_url: "",
    // SUB-MENU
    submenu_menu_id: null,
    submenu_id: null,
    submenu_name: "",
    submenu_description: "",
    submenu_url: "",
    // function
    function_id: null,
    function_type: RADIO_MODULE,
    function_menu_id: null,
    function_submenu_id: null,
    function_name: "",
    function_description: "",
  },
};
const OrganizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    addModuleList(state, action) {
      state.moduleList = action.payload;
    },
    addModule: {
      prepare(id, name, desc, url, status) {
        return { payload: { id, name, desc, url, status } };
      },
      reducer(state, action) {
        state.moduleData.id = action.payload.id;
        state.moduleData.module_name = action.payload.name;
        state.moduleData.description = action.payload.desc;
        state.moduleData.module_url = action.payload.url;
        state.moduleData.status = action.payload.status;
      },
    },
    addEntireMenu(state, action) {
      state.moduleData.menu = action.payload;
    },
    addModuleFunctionality(state, action) {
      state.moduleData.functionality = action.payload;
    },
    addFunctionalityDetails: {
      prepare(id, menu_id, submenu_id, type, name, desc) {
        return { payload: { id, menu_id, submenu_id, type, name, desc } };
      },
      reducer(state, action) {
        const { id, menu_id, submenu_id, type, name, desc } = action.payload;
        return {
          ...state,
          moduleData: {
            ...state.moduleData,
            function_id: id,
            function_menu_id: menu_id,
            function_submenu_id: submenu_id,
            function_type: type,
            function_name: name,
            function_description: desc,
          },
        };
      },
    },
    addMenu(state, action) {
      state.moduleData.menu = [...state.moduleData.menu, action.payload];
    },
    addMenuDetails: {
      prepare(id, name, desc, url) {
        return { payload: { id, name, desc, url } };
      },
      reducer(state, action) {
        const { id, name, desc, url } = action.payload;
        return {
          ...state,
          moduleData: {
            ...state.moduleData,
            menu_id: id,
            menu_name: name,
            menu_description: desc,
            menu_url: url,
          },
        };
      },
      // reducer(state, action) {
      //   state.moduleData.menu_id = action.payload.id;
      //   state.moduleData.menu_name = action.payload.name;
      //   state.moduleData.menu_description = action.payload.desc;
      //   state.moduleData.menu_url = action.payload.url;
      // },
    },
    addSubmenudetails: {
      prepare(menu_id, submenu_id, name, desc, url) {
        return { payload: { menu_id, submenu_id, name, desc, url } };
      },
      reducer(state, action) {
        const { menu_id, submenu_id, name, desc, url } = action.payload;
        return {
          ...state,
          moduleData: {
            ...state.moduleData,
            submenu_menu_id: menu_id,
            submenu_id: submenu_id,
            submenu_name: name,
            submenu_description: desc,
            submenu_url: url,
          },
        };
      },
    },
  },
});

export const {
  addModuleList,
  addModule,
  addEntireMenu,
  addModuleFunctionality,
  addFunctionalityDetails,
  addMenu,
  addMenuDetails,
  addSubmenudetails,
} = OrganizationSlice.actions;
export default OrganizationSlice.reducer;
