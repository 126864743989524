import { IconButton, Snackbar } from "@material-ui/core";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      vertical: "top",
      horizontal: "center",
      message: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("message")) {
      let message = localStorage.getItem("message");
      this.setState({ show: true, message: message });
    }
    setInterval(function () {
      localStorage.removeItem("message");
    }, 5000);
  }

  render() {
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({ show: false });
    };

    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          &times;
        </IconButton>
      </React.Fragment>
    );

    return (
      <footer className="footer">
        <div className=" d-sm-flex justify-content-center justify-content-sm-between py-2">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright ©
            <a
              href="https://berighthere.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              berighthere.com
            </a>
            <span className="pl-1">2022</span>
            <span className="pl-1">
              <b>v1.4</b>
            </span>
          </span>

          <Col md={6} sm={12}>
            <Snackbar
              open={this.state.show}
              autoHideDuration={1500}
              onClose={handleClose}
              action={action}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert
                onClose={handleClose}
                className="bg-success text-white"
                sx={{ width: "100%" }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
          </Col>
        </div>
      </footer>
    );
  }
}

export default Footer;
