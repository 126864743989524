import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import API from "../API_Services/ServiceLayer";
import { confirmAlert } from "react-confirm-alert";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import ScaleLoader from "react-spinners/ScaleLoader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { confirm } from "react-confirm-box";
import bsCustomFileInput from "bs-custom-file-input";

const MySwal = withReactContent(Swal);
export class AddProvider extends Component {
  state = {
    startDate: new Date(),
    countries: [],
  };

  constructor(props) {
    super(props);
    let date = new Date();
    date.setDate(date.getDate() + 1); // tomorrow
    this.state = {
      minDateValue: date.toISOString(),
      country_id: 101,
      validate: false,
      isPasswordSame: true,
      postal_code:"",
      loader: false,
      mobile_no:"",
      created_by: "",
      passwordIsTypePassword: true,
      cPasswordIsTypePassword: true,
      errors: {},
      providerNameRef: React.createRef(),
      contactpersonNameRef: React.createRef(),
      countryRef: React.createRef(),
      AddressRef: React.createRef(),
      stateRef: React.createRef(),
      cityRef: React.createRef(),
      postalcoderef: React.createRef(),
      passwordref: React.createRef(),
      confirmpassref: React.createRef(),
      mobilenoref: React.createRef(),
      emailref: React.createRef(),

    };
  }

  // componentDidMount() {
  //   // bsCustomFileInput.init();
  //   API.getData("api/country?limit=300")
  //     .then((res) => res.json())
  //     .then((result) => {
  //       this.setState({ countries: result.data.data });
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });

  //   let data = {
  //     country_id: 101,
  //   };
  //   API.postMethod("api/state/101", "post", data)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       this.setState({ statesData: result.data });
  //     });

  //   API.getData("api/role?limit=100")
  //     .then((res) => res.json())
  //     .then((result) => {
  //       this.setState({ roles: result.data.data });
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // }
  //run all the initial setup
  componentDidMount() {
    // bsCustomFileInput.init();
    //GET COUNTRIES LIST
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    console.log("laksh", "local", user?.id);
    this.setState({ created_by: user?.id });
    API.getData("user-management/api/v1/country?limit=300")
      .then((res) =>{return res.json()})
      .then((resultCountry) => {
        console.log("API-COUNTRY_LIST");
        this.setState({ countries: resultCountry?.data });
        //GET STATE LIST AGAINST INDIA
        let country_id = this.state.country_id ? this.state.country_id : 101;
        API.getData(`user-management/api/v1/state/${country_id}`)
          .then((res) => res.json())
          .then((result) => {
            console.log("API-STATE_LIST");
            this.setState({ statesData: result.data });
            //setting loader
            this.setState({ loader: true });
          })
          .catch((e) => {
            console.log("error", e);
            //setting loader
            this.setState({ loader: true });
          });
      })
      .catch((e) => {
        console.log("error", e);
        //setting loader
        this.setState({ loader: true });
      });
  }
  handleChange(e) {
    let name = e.target.name;
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name == "country_id") {
      API.getData(`user-management/api/v1/state/${e.target.value}`)
        .then((res) => res.json())
        .then((result) => {
          console.log("API-COUNTRY_LIST");
          this.setState({ statesData: result.data });
        });
    }
    //update cities_list when state change
    else if (e.target.name == "state_id") {
      API.getData(`user-management/api/v1/city/${e.target.value}`)

        .then((res) => res.json())
        .then((result) => {
          console.log("API-STATE_LIST");
          this.setState({ citiesData: result.data });
        });
    }
  }

  validateForm = () => {
    let {
      name,
      state_id,
      city_id,
      country_id,
      contact_person,
      password,
      password_confirmation,
      postal_code,
      mobile_no,
      email,
      address1
      
    } = this.state;
    const errors = {};

    if (!name) {
      errors.name = 'Enter Provider Name';
    }
    if (!address1) {
      errors.address1 = 'Choose Address';
    }
    if (!state_id) {
      errors.state_id = 'Choose state';
    }
    if (!city_id) {
      errors.city_id = 'Choose city';
    }
    if (!country_id) {
      errors.country_id = 'Choose Country';
    }
    if (!contact_person) {
      errors.contact_person = 'Name is required';
    }
    if (!password) {
      errors.password = 'Enter Password';
    }
    if (((password.length>0) && (password.length<8))) {
      errors.password_correct = 'Password atleast 8 digit';
    }
    if (password_confirmation !==password) {
      errors.password_confirmation = 'Password Mistmatch';
    }
    
    if (!postal_code) {
      errors.postal_code = 'Postal code is required';
    }
    if (((postal_code.length>0) && (postal_code.length!==6))) {
      errors.postal_code1 = 'Enter 6 digits';
    } 
    if (!mobile_no) {
      errors.contact1 = 'Mobile Number is required';
    } 
    if (((mobile_no.length>0) && (mobile_no.length!==10))) {
      errors.contact = 'Enter 10 digits';
    } 
    if (!email) {
      errors.email = 'Email is required';
    } 
   

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };


  handleStateChange(e) {
    let name = e.target.name;

    this.setState({ [name]: e.target.value });

    if (e.target.name === "state_id") {
      let data = {
        state_id: e.target.value,
      };
      API.postMethod("api/city/" + e.target.value, "post", data)
        .then((res) => res.json())
        .then((result) => {
          this.setState({ citiesData: result.data });
        });
    }
  }

  //check wheteher password and confirm passowrd is same
  check_password = () => {
    console.log("laksh", "run check");
    if (this.state.password != this.state.password_confirmation) {
      this.setState({ isPasswordSame: false });
      return false;
    } else {
      this.setState({ isPasswordSame: true });
      return true;
    }
  };

  handleFormSubmit = (e) => {
    // e.preventDefault();
    // this.setState({ loading: true });

    // let data = {
    //   name: this.state.name,
    //   //password: this.state.password,
    //   first_name: this.state.first_name,
    //   last_name: this.state.last_name,
    //   email: this.state.email,
    //   // provider_email: this.state.email,
    //   // provider_mobile: this.state.mobile_no,
    //   //mobile_no: this.state.mobile_no,
    //   profile_img_path: this.state.profile_img_path,
    //   address1: this.state.address1,
    //   address2: this.state.address2,
    //   city_id: this.state.city_id,
    //   state_id: this.state.state_id,
    //   country_id: this.state.country_id,
    //   zipcode: this.state.zipcode,
    //   contact_person: this.state.contact_person,
    //   contact_number: this.state.contact_number,
    //   role_id: 3,
    //   status: 1,
    // };

    // console.log(data);

    // API.postMethod("api/provider", "post", data)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     // if(result.status == "success")

    //     if (result.status === "success") {
    //       this.setState({ loading: false });

    //       console.log(result);
    //       localStorage.setItem("message", "Provider Added Successfully");
    //       window.location.reload();
    //       window.location.href = "/list-provider";
    //       // window.location.reload();
    //     } else {
    //       console.log(result);
    //       this.setState({ messageErr: result.message });
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    e.preventDefault();
    const isValid = this.validateForm();

    if(isValid) {
    let frm = e.currentTarget;
    let isSame = this.check_password();
    console.log("laksh", "run");

      //get local data
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      console.log("Thamizh", "local", user?.id);
  
    if (frm.checkValidity() === false) {
      console.log("laksh", frm.checkValidity());
      this.setState({ validate: true });
    }
    if (frm.checkValidity() === true && isSame === true) {
      this.setState({ loading: true });
      let emailVerifiedAt = new Date().toISOString();

         // to get onboard person role name
         let objectsWithStatusOne =user.roles.filter(obj => obj.status === 1);
         let UseronboardString= objectsWithStatusOne.map(obj => obj.role_name);
         const OnboardPerson = UseronboardString.length > 0 ? UseronboardString[0] : null;
         console.log(OnboardPerson, " <<<<<<<>>>>>>>>>>")

         
      let data = {
        name: this.state.name,
        mobile_no: this.state.mobile_no,
        email: this.state.email,
        password: this.state.password,
        address1: this.state.address1,
        address2: this.state.address2,
        city_id: Number(this.state.city_id),
        state_id: Number(this.state.state_id),
        country_id: Number(this.state.country_id),
        zipcode: Number(this.state.postal_code),
        dob: this.state.dob,
        contact_person: this.state.contact_person,
        status: 1, //Number(this.state.status)
        role: "Provider",
        is_verified: 0,
        password_confirmation: this.state.password_confirmation,
        contact_number: this.state.mobile_no,
        email_verified_at: emailVerifiedAt,
        created_by: this.state.created_by,
        register_from: "Web",
        on_boarding_mode:OnboardPerson,

      };
      if (this.state.address2) data["address2"] = this.state.address2;
      console.log("laksh", data);
      API.postMethod("api/provider", "post", data)

        .then((res) => res.json())
        .then((result) => {
          console.log("API-USER_CREATION");
          if (result.status) {
            MySwal.fire({
              title: <strong>sucess</strong>,
              html: <i>{result.message}</i>,
              icon: "success",
              timer: 10000,
              showConfirmButton: true,
            }).then(function () {
              MySwal.close();
            });
            this.setState({
              loading: false,
              message: "Provider added successfully",
            });
            localStorage.setItem("message", this.state.message);
            window.location.href = "user-management/property-providers";
          } else {
            MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>{result.message}</i>,
              icon: "error",
              timer: 10000,
              showConfirmButton: true,
            }).then(function () {
              MySwal.close();
            });
            this.setState({ messageErr: result.message, loading: false });
            this.setState({ mobileErr: result.mobileNo });
            this.setState({ emailErr: result.email });
            this.setState({ addrErr: result.address1 });
            this.setState({ nameErr: result.name });
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    
  }else {
    if (this.state.providerNameRef.current && this.state.errors.name) {
      this.state.providerNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.contactpersonNameRef.current && this.state.errors.contact_person) {
      this.state.contactpersonNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.AddressRef.current && this.state.errors.address1) {
      this.state.AddressRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.countryRef.current && this.state.errors.country_id) {
      this.state.countryRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.stateRef.current && this.state.errors.state_id) {
      this.state.stateRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.cityRef.current && this.state.errors.city_id) {
      this.state.cityRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.postalcoderef.current && this.state.errors.postal_code) {
      this.state.postalcoderef.current.scrollIntoView({ behavior: 'smooth' });
      
    } else if (this.state.postalcoderef.current && this.state.errors.postal_code1) {
      this.state.postalcoderef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.passwordref.current && this.state.errors.password) {
      this.state.passwordref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.passwordref.current && this.state.errors.password_correct) {
      this.state.passwordref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.confirmpassref.current && this.state.errors.password_confirmation) {
      this.state.confirmpassref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.mobilenoref.current && this.state.errors.contact) {
      this.state.mobilenoref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.mobilenoref.current && this.state.errors.contact1) {
      this.state.mobilenoref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.emailref.current && this.state.errors.email) {
      this.state.emailref.current.scrollIntoView({ behavior: 'smooth' });
    }
    return
  }
  };

  handleClear = (data) => {
    this.setState({ id: data.id });

    confirmAlert({
      //title: "Confirm to submit",
      message:
        "All data populated in the field will be discarded, do you wish to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            window.location.href = "user-management/property-providers";
          },
        },
        {
          label: "No",
        },
      ],
      closeOnClickOutside: false,
      closeOnEscape: false,
      overlayClassName: "react-confirm-alert-overlay",
    });
  };

  render() {

    const { errors } = this.state;


    let month = new Date().getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let dd = new Date().getDate();
    dd = dd < 10 ? "0" + dd : dd;
    let date = new Date().getFullYear() - 18 + "-" + month + "-" + dd;
    console.log("hello i am there");
    return (
      <>
        {this.state.loader == true ? (
          <>
            <div className="card">
              {/* page title */}
              <div className="col-sm-12 text-left">
                <h6 className="breadcrumps_bg">
                  <a className="text-primary" href="/">
                    Home &nbsp;
                  </a>{" "}
                  / User management &nbsp;
                  <a className="text-primary" href="/user-management/property-providers">
                    / Property Providers
                  </a>{" "}
                  <span className="text-muted"> / Add Provider</span>
                </h6>
              </div>
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title">Horizontal Two column</h4> */}

                      <Form
                        className="form-sample"
                        noValidate
                        validated={this.state.validate}
                        onSubmit={this.handleFormSubmit}
                      >
                        <div className="row">
                          {/* Provider Name */}
                          <div className="col-md-6 col-sm-12"    ref={this.state.providerNameRef}  >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Provider Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  autoFocus
                                  type="text"
                                  placeholder="Enter First Name"
                                  name="name"
                                  pattern="^[A-Za-z][A-Za-z0-9_\s ]*$"
                                  onKeyPress={(event) => {
                                    if (!/^[A-Za-z0-9_\s ]*$/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Enter only Alphanumeric and Should not allow special characters other than space or underscore"
                                  maxLength={35}
                                  minLength={3}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Name.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.name && <small className="text-red">{errors.name}</small>}

                          </div>

                          {/* Date of Birth */}
                          <div className="col-md-6 col-sm-12 ">
                            <Form.Group className="row">
                              <label
                                htmlFor="date"
                                className="col-sm-12 col-md-12 col-lg-12 col-form-label"
                              >
                                Date of Birth{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  type="date"
                                  onChange={(e) => this.handleChange(e)}
                                  name="dob"
                                  max={date}
                                  id="date"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Age should be minimum 18 years"
                                  placeholder="Select Date"
                                  // required
                                />
                               
                              </div>
                            </Form.Group>
                          </div>

                          {/* Email */}
                          <div className="col-md-6 col-sm-12"   ref={this.state.emailref} >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Email <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  type="email"
                                  // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                  name="email"
                                  // onBlur={(e) => this.emailValidate(e.target.value)}
                                  placeholder="Enter Provider Email ID"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Email.
                                </Form.Control.Feedback>
                                {this.state.emailValidates == true ? (
                                  ""
                                ) : (
                                  <span className="text-danger text-small">
                                    {this.state.emailMessage}
                                  </span>
                                )}
                              </div>
                            </Form.Group>
                            {errors.email && <small className="text-red">{errors.email}</small>}

                          </div>

                          {/* <div className="col-md-6 col-sm-12">
                      <Form.Group className="row">
                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                          Profile Image
                        </label>
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                          <Form.Control
                            className="form-control"
                            type="file"
                            name="profile_img_path"
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>
                      </Form.Group>
                    </div> */}
                          {/* Contact Person Name */}
                          <div className="col-md-6 col-sm-12"   ref={this.state.contactpersonNameRef} >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Contact Person Name
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  type="text"
                                  pattern="^[A-Za-z][A-Za-z0-9_\s ]*$"
                                  name="contact_person"
                                  onKeyPress={(event) => {
                                    if (!/^[A-Za-z0-9_\s ]*$/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Enter only Alphanumeric and Should not allow special characters other than space or underscore"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  placeholder="Enter Contact Person Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Contact Person Name.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.contact_person && <small className="text-red">{errors.contact_person}</small>}

                          </div>
                          {/* Contact Number */}
                          <div className="col-md-6 col-sm-12"   ref={this.state.mobilenoref} >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Contact Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  placeholder="Enter Contact Number"
                                  maxLength="10"
                                  minLength="10"
                                  type="tel"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Value should not exceed or less than 10"
                                  name="mobile_no"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Contact Number.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.contact1 && <small className="text-red">{errors.contact1}</small>}
                            {errors.contact && <small className="text-red">{errors.contact}</small>}

                          </div>
                          {/* <div className="col-md-6 col-sm-12">
                      <Form.Group className="row">
                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                          Status
                        </label>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <select
                            name="status"
                            defaultValue={this.state.status}
                            onChange={this.onChangeHandler}
                            className="form-control"
                            required
                          >
                            <option selected value="Select City">
                              Select Status
                            </option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div> */}

                          {/* Address 1  */}
                          <div className="col-md-6 col-sm-12"  ref={this.state.AddressRef} >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Address 1 <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  type="text"
                                  name="address1"
                                  placeholder="Enter Address Line 1"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                  maxLength={150}
                                  minLength={3}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Address 1.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.address1 && <small className="text-red">{errors.address1}</small>}

                          </div>
                          {/* Address 2 */}
                          <div className="col-md-6 col-sm-12">
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Address 2
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  type="text"
                                  name="address2"
                                  placeholder="Enter Address Line 2"
                                  onChange={(e) => this.handleChange(e)}
                                  maxLength={150}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Address 2.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                          {/*   Country */}
                          <div className="col-md-6 col-sm-12"  ref={this.state.countryRef} >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <select
                                  className="form-control"
                                  name="country_id"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                >
                                  <option value="">Select Country</option>
                                  {this.state.countries &&
                                    this.state.countries.map((value, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            this.state.country_id == value.id
                                              ? true
                                              : false
                                          }
                                          value={value.id}
                                        >
                                          {value.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <Form.Control.Feedback type="invalid">
                                  Please Select Country.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.country_id && <small className="text-red">{errors.country_id}</small>}

                          </div>
                          <div className="col-md-6 col-sm-12"  ref={this.state.stateRef} >
                            {/* State */}
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                State <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <select
                                  className="form-control"
                                  name="state_id"
                                  onChange={(e) => this.handleStateChange(e)}
                                  required
                                >
                                  <option value="">Select State</option>
                                  {this.state.statesData &&
                                    this.state.statesData.map((value, key) => {
                                      return (
                                        <option key={key} value={value.id}>
                                          {value.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <Form.Control.Feedback type="invalid">
                                  Please Select State.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.state_id && <small className="text-red">{errors.state_id}</small>}

                          </div>
                          {/* City */}
                          <div className="col-md-6 col-sm-12"  ref={this.state.cityRef} >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                City <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <select
                                  className="form-control"
                                  name="city_id"
                                  onChange={(e) => this.handleStateChange(e)}
                                  required
                                >
                                  <option value="">Select City</option>
                                  {this.state.citiesData &&
                                    this.state.citiesData.map((value, key) => {
                                      return (
                                        <option key={key} value={value.id}>
                                          {value.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <Form.Control.Feedback type="invalid">
                                  Please Select City.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.city_id && <small className="text-red">{errors.city_id}</small>}

                          </div>
                          {/* Postal Code */}
                          <div className="col-md-6 col-sm-12"  ref={this.state.postalcoderef} >
                            <Form.Group className="row">
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Postal Code{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Postal Code"
                                  maxLength={6}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  name="postal_code"
                                  onChange={(e) => this.handleChange(e)}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Postal code.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            {errors.postal_code && <small className="text-red">{errors.postal_code}</small>}
                            {errors.postal_code1 && <small className="text-red">{errors.postal_code1}</small>}

                          </div>
                          {/* Password */}
                          <div className="col-md-6 col-sm-12"  ref={this.state.passwordref} >
                            <Form.Group>
                              <label
                                htmlFor="company"
                                className="col-sm-12 col-md-12 col-lg-12 col-form-label"
                              >
                                Password <span className="text-danger">*</span>
                              </label>
                              <Form.Control
                                required
                                type={
                                  this.state.passwordIsTypePassword === true
                                    ? "password"
                                    : "text"
                                }
                                name="password"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.password}
                                id="password"
                                placeholder="Enter password"
                                style={{ position: "relative" }}
                              />
                              {this.state.passwordIsTypePassword === true ? (
                                <>
                                  <button
                                    type="button"
                                    style={{
                                      position: "absolute",
                                      top: "60px",
                                      right: "20px",
                                      border: "none",
                                      background: "none",
                                      borderLeft: "1px solid #ccc",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        passwordIsTypePassword: false,
                                      })
                                    }
                                  >
                                    <AiOutlineEyeInvisible
                                      size="1.5em"
                                      color="#111"
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    style={{
                                      position: "absolute",
                                      top: "60px",
                                      right: "20px",
                                      border: "none",
                                      background: "none",
                                      borderLeft: "1px solid #ccc",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        passwordIsTypePassword: true,
                                      })
                                    }
                                  >
                                    <AiOutlineEye size="1.5em" color="#111" />
                                  </button>
                                </>
                              )}
                              <Form.Control.Feedback type="invalid">
                                Please Enter Password.
                              </Form.Control.Feedback>
                            </Form.Group>
                            {errors.password && <small className="text-red">{errors.password}</small>}
                            {errors.password_correct && <small className="text-red">{errors.password_correct}</small>}

                          </div>
                          {/* Confirm Password */}
                          <div className="col-md-6 col-sm-12"  ref={this.state.confirmpassref} >
                            <Form.Group>
                              <label className="col-sm-12 col-md-12 col-lg-12 col-form-label">
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Form.Control
                                required
                                type={
                                  this.state.cPasswordIsTypePassword === true
                                    ? "password"
                                    : "text"
                                }
                                name="password_confirmation"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.password_confirmation}
                                id="confirm_password"
                                placeholder="Enter Confirm password"
                                style={{ position: "relative" }}
                              />
                              {this.state.cPasswordIsTypePassword === true ? (
                                <>
                                  <button
                                    type="button"
                                    style={{
                                      position: "absolute",
                                      top: "60px",
                                      right: "20px",
                                      border: "none",
                                      background: "none",
                                      borderLeft: "1px solid #ccc",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        cPasswordIsTypePassword: false,
                                      })
                                    }
                                  >
                                    <AiOutlineEyeInvisible
                                      size="1.5em"
                                      color="#111"
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    style={{
                                      position: "absolute",
                                      top: "60px",
                                      right: "20px",
                                      border: "none",
                                      background: "none",
                                      borderLeft: "1px solid #ccc",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        cPasswordIsTypePassword: true,
                                      })
                                    }
                                  >
                                    <AiOutlineEye size="1.5em" color="#111" />
                                  </button>
                                </>
                              )}
                              <Form.Control.Feedback type="invalid">
                                Please Enter Confirm Password.
                              </Form.Control.Feedback>
                              <p
                                style={{
                                  display:
                                    this.state.isPasswordSame == true
                                      ? "none"
                                      : "block",
                                  color: "red",
                                }}
                              >
                                Password and Confirm Password does not match
                              </p>
                            </Form.Group>
                            {errors.password_confirmation && <small className="text-red">{errors.password_confirmation}</small>}

                          </div>
                        </div>

                        {this.state.messageErr ? (
                          <span className="text-danger">
                            {this.state.messageErr}
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="d-flex flex-wrap justify-content-end mr-3 mt-5">
                          <button
                            type="button"
                            className="btn btn-light rounded-0 rounded-0"
                            onClick={(e) => this.handleClear(e)}
                          >
                            Clear
                          </button>
                          {this.state.loading ? (
                            <button
                              type="submit"
                              disabled
                              className="btn btn-primary ml-2 rounded-0"
                            >
                              Onboard
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary ml-2 rounded-0"
                            >
                              Onboard
                            </button>
                          )}
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Container
              style={{
                height: "60vh",
              }}
              className=" d-flex align-items-center justify-content-center "
            >
              <ScaleLoader color="orange" />
              {/* size={100} */}
            </Container>
          </>
        )}
      </>
    );
  }
}

export default AddProvider;
