// import { combineReducers, createStore } from "redux";
import organizationReducer from "../app/Property/organization/OrganizationSlice";
import AddMenuReducer from "./InitialMenuSlice";

import { configureStore } from "@reduxjs/toolkit";

// const rootReducer = combineReducers({
//   organization: organizationReducer,
// });

// const store = createStore(rootReducer);
// import { configureStore } from "@reduxjs/toolkit";
// import organizationSlice from "../app/Property/organization/OrganizationSlice";

const store = configureStore({
  reducer: {
    organization: organizationReducer,
    addMenu: AddMenuReducer,
  },
});
// console.log("laksh-redux", store);

export default store;
