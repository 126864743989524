import React from "react";

export const TAB_MODULE = 1;
export const TAB_MENU = 2;
export const TAB_SUBMENU = 3;
export const TAB_FUNCTION = 4;

export const RADIO_MODULE = 1;
export const RADIO_MENU = 2;
export const RADIO_SUBMENU = 3;

export function capitalizeFirstLetter(string) {
  if (!string || typeof string !== "string") return;
  let firstLetter = string[0].toUpperCase();
  let remainingLetter = string.slice(1);
  return firstLetter + remainingLetter;
}

export function makeAsRequiredField(label) {
  return (
    <>
      <span>{label}</span>
      <span style={{ color: "red", marginLeft: "8px" }}>*</span>
    </>
  );
}

export const RULE_MODULE_NAME = {
  required: "Module Name is required",
  minLength: { value: 5, message: "Minimum 6 characters required" },
  // maxLength: { value: 16, message: "Maximum 16 characters only" },
};

export const RULE_MODULE_DESC = {
  required: "Module Description is required",
  minLength: { value: 5, message: "Minimum 6 characters required" },
};

export const RULE_MODULE_URL = {
  required: "URL  is required",
};

export const RULE_MENU_NAME = {
  required: "Menu Name is required",
  minLength: { value: 3, message: "Minimum 3 characters required" },
  // maxLength: { value: 16, message: "Maximum 16 characters only" },
};

export const RULE_MENU_DESC = {
  required: "Menu Description is required",
  minLength: { value: 5, message: "Minimum 6 characters required" },
};

export const RULE_MENU_URL = {
  required: "Menu URL is required",
};

export const RULE_SUBMENU_NAME = {
  required: "Sub-Menu Name is required",
  minLength: { value: 5, message: "Minimum 6 characters required" },
  // maxLength: { value: 16, message: "Maximum 16 characters only" },
};

export const RULE_SUBMENU_DESC = {
  required: "Sub-Menu Description is required",
  minLength: { value: 5, message: "Minimum 6 characters required" },
};
export const RULE_SUBMENU_URL = {
  required: "Sub-Menu URL is required",
};

export const RULE_SUBMENU_MENU = {
  required: "Select Menu is required",
};

export const RULE_FUNCTION_TYPE = {
  required: "Function Type is required",
};

export const RULE_FUNCTION_MENU = {
  required: "Select Menu is required",
};

export const RULE_FUNCTION_SUBMENU = {
  required: "Select Sub-Menu is required",
};

export const RULE_FUNCTION_NAME = {
  required: "Function Name is required",
  minLength: { value: 5, message: "Minimum 6 characters required" },
  // maxLength: { value: 16, message: "Maximum 16 characters only" },
};

export const RULE_FUNCTION_DESC = {
  required: "Function Description is required",
  minLength: { value: 5, message: "Minimum 6 characters required" },
};
