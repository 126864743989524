import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "./Shared/Spinner";
// import GuestList from "./visitorManagement/GuestList";

const Dashboard = lazy(() => import("./Dashboard/Dashboard"));

//locations
const Location = lazy(() => import("./Locations/AddLocation"));

//property
const AddProperty = lazy(() => import("./Property/AddProperty"));
const PropertyList = lazy(() => import("./Property/PropertyList"));
const EmployeePropertyList = lazy(() =>
  import("./Property/EmployeePropertyList")
);
const PropertyMaster = lazy(() => import("./Property/MasterList"));
const PropertyMaster2 = lazy(() => import("./Property/Property-2/MasterList2"));
const OrganizationMaster = lazy(() =>
  import("./Property/organization/Organization")
);
const AddModule = lazy(() => import("./Property/organization/AddModule"));

// const PropertyMasters = lazy(() => import('./Property/Property-2/MasterList2'));
const PropertyDetails = lazy(() => import("./Property/PropertyDetails"));

//Provider
const AddProvider = lazy(() => import("./User/AddProvider"));
const EditProvider = lazy(() => import("./User/EditProvider"));
const ProviderData = lazy(() => import("./User/ProviderData"));

const AddPropertyMappedUser = lazy(() =>
  import("./User/AddPropertyMappedUser")
);
const EditPropertyUser = lazy(() => import("./User/editPropertyUser"));
const PropertyUser = lazy(() => import("./User/PropertyUserData"));

// import ProviderData from "./User/ProviderData";

//Member
const AddMember = lazy(() => import("./User/AddMembers"));
const EditMember = lazy(() => import("./User/EditMember"));
const MemberData = lazy(() => import("./User/MemberData"));

//Sales
const AddSales = lazy(() => import("./User/AddSales"));
const EditSales = lazy(() => import("./User/EditSales"));
const SalesData = lazy(() => import("./User/SalesData"));
const AddStaff = lazy(() => import("./User/AddStaff"));
const AddOrganizationStaff = lazy(() => import("./User/AddOrganizationStaff"));
const StaffData = lazy(() => import("./User/StaffData"));
const OrganizationStaffData = lazy(() => import("./User/OrganizationStaff"));
const EditStaff = lazy(() => import("./User/EditStaff"));
const EditOrganizationStaff = lazy(() =>
  import("./User/EditOrganizationStaff")
);
const TempUser = lazy(() => import("./User/TempUser"));

//Corporate
const EditCorporate = lazy(() => import("./User/EditCorporate"));
const AddCorporate = lazy(() => import("./User/AddCorporate"));
const CorporateData = lazy(() => import("./User/CorporateData"));
const CorporateList = lazy(() => import("./User/CorporateList"));
const BulkCorporate = lazy(() => import("./User/CorporateOnbardBulkEmployees"));

const CorporateMapProperties = lazy(() => import("./Corporate/AddCorporate"));

//sub admin
const AddSubAdmin = lazy(() => import("./User/AddSubAdmin"));
const SubAdminList = lazy(() => import("./User/SubAdminData"));
const SubAdminData = lazy(() => import("./User/SubAdminData"));
const EditSubAdmin = lazy(() => import("./User/EditSubAdmin"));

//roles
const AddRoles = lazy(() => import("./Roles/AddRoles"));
const RolesList = lazy(() => import("./Roles/RolesList"));

//access component
const AddAccess = lazy(() => import("./Roles/AddAccess"));
const EditAccess = lazy(() => import("./Roles/EditAccess"));
const AccessList = lazy(() => import("./Roles/AccessList"));
const AccessRight = lazy(() => import("./Roles/AccessRights"));

// = lazy(()=>import(''));

//Menu
const AddMenu = lazy(() => import("./Menu/AddMenu"));
const AddSubMenu = lazy(() => import("./Menu/AddSubMenu"));
const MenuList = lazy(() => import("./Menu/MenuList"));
const SubMenuList = lazy(() => import("./Menu/SubMenuList"));

//Bookings
const AddBooking = lazy(() => import("./Booking/AddBooking"));
const BookigsList = lazy(() => import("./Booking/BookingsList"));
const EmployeeBookingsList = lazy(() =>
  import("./Booking/EmployeeBookingsList")
);
const BookingDetails = lazy(() => import("./Booking/BookingDetails"));
const BookigTypeList = lazy(() => import("./Booking/BookingTypeList"));
const BookingCancelationRequest = lazy(() =>
  import("./Booking/BookingCancelationRequest")
);

const Login = lazy(() => import("./User-Pages/Login"));

//SLAs

const RuleEngineList = lazy(() => import("./RuleEngine/RuleEngineList"));
//Help edsk
const Helpdesk = lazy(() => import("./Helpdesk/HelpdeskTabs"));
const IssueRaise = lazy(() => import("./Helpdesk/IssueRaisePage"));

// Invoices
const InvoiceList = lazy(() => import("./Invoice/InvoiceList"));
const InvoicePdf = lazy(() => import("./Invoice/InvoicePdf"));
const DiscountCoupons = lazy(() => import("./Invoice/DiscountCoupons"));

//visitor-management
const GuestList = lazy(() => import("./visitorManagement/GuestList"));

let AccessRoles = JSON.parse(localStorage.getItem("role"));

console.log(AccessRoles,'accessroles')




class AppRoutes extends Component {

  componentDidMount() {
    
    // Check if AccessRoles is equal to 1 or 9
    if (AccessRoles === 1 || AccessRoles === 9) {
      // Perform some action if AccessRoles is 1 or 9
      localStorage.setItem('adminAccess', '001');
    } 
    // else {
    //   // Perform some other action if AccessRoles is not 1 or 9
    //   console.log('AccessRoles is not 1 or 9');
    // }

  }
  render() {

  

    let pathname = JSON.parse(localStorage.getItem("pathname"));
    console.log("APP ROUTE--->", pathname);
    return (
      <Suspense fallback={<Spinner />}>
        {!localStorage.getItem("token") ? (
          <Switch>
            <Route exact path="/" component={Login} />
            <Redirect to="/" />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />

            <Route exact path="/locations" component={Location} />

            <Route exact path="/provider-add" component={AddProvider} />
            <Route
              exact
              path="/user-management/property-providers"
              component={ProviderData}
            />
            <Route exact path="/provider-edit/:id" component={EditProvider} />

            <Route
              exact
              path="/user-management/property-user"
              component={PropertyUser}
            />
            <Route
              exact
              path="/provider/propertyuser-add"
              component={AddPropertyMappedUser}
            />
            <Route
              exact
              path="/provider/propertyuser-edit/:id"
              component={EditPropertyUser}
            />

            <Route exact path="/member-add" component={AddMember} />
            <Route
              exact
              path="/user-management/members"
              component={MemberData}
            />
            <Route path="/member-edit/:id" component={EditMember} />
            <Route
              exact
              path="/user-management/sales-executive"
              component={SalesData}
            />
            <Route exact path="/sales-add" component={AddSales} />
            <Route path="/sales-edit/:id" component={EditSales} />
            <Route path="/user-management/staff" component={StaffData} />
            <Route
              path="/user-management/organization-staff"
              component={OrganizationStaffData}
            />
            <Route path="/staff-add/" component={AddStaff} />
            <Route path="/staff-edit/:id" component={EditStaff} />
            <Route
              path="/organization-staff-edit/:id"
              component={EditOrganizationStaff}
            />
            <Route
              path="/organization-staff-add/"
              component={AddOrganizationStaff}
            />

            <Route path="/user/add" component={TempUser} />

            <Route exact path="/corporate-add" component={CorporateList} />
            <Route
              exact
              path="/user-management/corporates"
              component={CorporateData}
            />
            {/* <Route exact path="/onboard-corporate" component={BulkCorporate} /> */}
            {/* <Route exact path="/details-corporate" component={CorporateList} /> */}

            <Route exact path="/invoice-pdf/:id" component={InvoicePdf} />
            <Route
              exact
              path="/finance-management/member-invoices"
              component={InvoiceList}
            />
            <Route
              exact
              path="/finance-management/discount-coupons"
              component={DiscountCoupons}
            />

            <Route
              exact
              path="/list-corporate-properties"
              component={CorporateMapProperties}
            />
            <Route path="/corporate-edit/:id" component={CorporateList} />

            <Route exact path="/subadmin-add" component={AddSubAdmin} />
            <Route
              exact
              path="/user-management/admin"
              component={SubAdminData}
            />
            <Route exact path="/subadmin-edit/:id" component={EditSubAdmin} />

            <Route
              exact
              path="/property/add-new-property"
              component={AddProperty}
            />
            <Route exact path="/property-edit/:id" component={AddProperty} />

            <Route exact path="/property" component={PropertyList} />

            <Route
              exact
              path="/employee-property-list/:id"
              component={EmployeePropertyList}
            />

            <Route
              exact
              path="/master-management/master-list"
              component={PropertyMaster}
            />
            <Route
              exact
              path="/master-management/other-master"
              component={PropertyMaster2}
            />
            <Route
              exact
              path="/master-management/organization"
              component={OrganizationMaster}
            />
            <Route
              exact
              path="/master-organization/add"
              component={AddModule}
            />
            <Route
              exact
              path="/master-organization/edit/:id"
              component={AddModule}
            />

            <Route
              exact
              path="/property-terms-conditions"
              component={PropertyList}
            />
            <Route
              exact
              path="/property-details/:id"
              component={PropertyDetails}
            />

            <Route exact path="/roles-add" component={AddRoles} />
            <Route exact path="/master-roles" component={RolesList} />

            <Route exact path="/access-add" component={AddAccess} />
            <Route exact path="/access-edit/:id" component={EditAccess} />
            <Route exact path="/access-list" component={AccessList} />
            <Route exact path="/access-rights" component={AccessRight} />

            <Route exact path="/menu-add" component={AddMenu} />
            <Route exact path="/submaster-menu/:id" component={SubMenuList} />
            <Route exact path="/submenu-add/:id" component={AddSubMenu} />
            <Route exact path="/master-menu" component={MenuList} />

            <Route exact path="/booking-add" component={AddBooking} />
            <Route
              exact
              path="/booking-management/member-booking-list"
              component={BookigsList}
            />
            <Route
              exact
              path="/booking-management/cancellation-booking-list"
              component={BookingCancelationRequest}
            />
            <Route
              exact
              path="/booking-management/employee-booking-list"
              component={EmployeeBookingsList}
            />
            <Route path="/booking-details/:id" component={BookingDetails} />
            <Route exact path="/bookingtype-list" component={BookigTypeList} />

            <Route exact path="/ruleengine" component={RuleEngineList} />
            <Route exact path="/help-desk" component={Helpdesk} />
            <Route exact path="/helpdesk/issue-raise" component={IssueRaise} />

            {/* visitormanagement */}
            <Route
              exact
              path="/visitor-management/guest"
              component={GuestList}
            />

            <Redirect to="/dashboard" />
          </Switch>
        )}
      </Suspense>
    );
  }
}

export default AppRoutes;
