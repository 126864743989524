import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredMenu: [],
};
const AddMenuSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    addMenu(state, action) {
      state.filteredMenu = action.payload;
    },
  },
});

export const { addMenu } = AddMenuSlice.actions;
export default AddMenuSlice.reducer;
